<template>
	<div class="minBox">
		<slide-show :bannerList="bannerList" :bannerHeight="390"></slide-show>
		<div class="searchBar">
			<Search
				type="2205"
				:keys="getListParams.key"
				@searchKeyFun="searchKeyFun"
			></Search>
		</div>
		<template>
			<div class="main_Div contBox">
				<div class="classify">
					<div class="menuBox flex-ct">
						<div class="menu" v-for="(item, index) in menuList" :key="index" @click="jump(item)">
							<div class="menuImg">
								<img :src="item.img" alt="">
							</div>
							<div class="menuName">{{item.title}}</div>
						</div>
					</div>
				</div>
				<div class="tabsBox">
					<div class="tabs" :class="{tabsActive: item.name == tabsActiveNmae}" @click="setTabsActiveFun(item)" v-for="(item, index) in tabsList" :key="index">
						<img :src="item.icon" alt="">
						<div class="tabName">
							{{item.name}}
						</div>
					</div>
				</div>
				<div class="ListBox">
					<chartlet-list
						ref="list"
						:colNum = "tabsActiveNmae"
						:chartletObj="chartletObj"
						:loading="loading"
						:listStatus="listStatus"
						@nextPage="nextPage"
					></chartlet-list>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import SlideShow from "./componts/SlideShow.vue";
import Search from "@/components/Search";
import { 
	pictureGetBanner,
	getPictureCateList,
	
} from "../../Api";
import { mapGetters } from "vuex";
import chartletList from "./componts/chartletList.vue"

export default {
	name: "TypeChartlet",
	components: {
		SlideShow,
		Search,
		chartletList
	},
	data() {
		return {
			bannerList: [],
			menuList: [],
			tabsList: [{
					icon: require("../../assets/image/chartlet/album.png"),
					name: "贴图专辑",
					status: {'is_album': 1, 'order': 1},
				},{
					icon: require("../../assets/image/chartlet/recommend.png"),
					name: "推荐贴图",
					status: {'order': 2},
				},{
					icon: require("../../assets/image/chartlet/free.png"),
					name: "免费贴图",
					status: {'is_free': 0, 'order': 1},
				},{
					icon: require("../../assets/image/chartlet/seamless.png"),
					name: "无缝贴图",
					status: {'type': 1, 'order': 1},
				},{
					icon: require("../../assets/image/chartlet/brand.png"),
					name: "品牌贴图",
					status: {'is_brand': 1},
				},
			],
			tabsActiveNmae: "贴图专辑",
			
			
			loading: false,
			listStatus: {
				isData: true,	// 判断是否有下一页
				isNull: false	// 判断是否为空
			},
			getListParams: {
				id: "",	// 分类id
				key: "",
				is_free: "",	// 是否付费 0-否 1-是
				is_album: 1,	// 是否专辑 0-否 1-是
				type: "",	// 是否无缝 0-否 1-是
				is_brand: "",	// 是否品牌 0-否 1-是
				order: "",	// 排序方式 1-最新 2-最热
				is_vip: "",	// 是否vip 0-否 1-是
				page: 0,
			},
		};
	},
	created() {
		if (this.$route.query.search) {
			this.getListParams.page = 1;
			this.getListParams.key = this.$route.query.search;
		} else {
			this.getListParams.page = 1;
		}
		this.initData();
	},
	methods: {
		initData() {
			pictureGetBanner().then((res) => {
				this.bannerList = res.data;
			});
			getPictureCateList().then((res) => {
				this.menuList = res.data;
			});
		},
		jump(item) {
			const {href} = this.$router.resolve({ path: "/chartlet/index", query:{child1: item.id}});
			window.open(href, '_blank')
		},
		setTabsActiveFun(data) {
			this.$refs.list&&this.$refs.list.clear();
			if (this.tabsActiveNmae != data.name) {
				let params= {
					is_free: "",	// 是否付费 0-否 1-是
					is_album: "",	// 是否专辑 0-否 1-是
					type: "",	// 是否无缝 0-否 1-是
					is_brand: "",	// 是否品牌 0-否 1-是
					order: 2,	// 排序方式 1-最新 2-最热
					page: 1
				}
				this.getListParams = {...this.getListParams, ...params, ...data.status}
				this.tabsActiveNmae = data.name;
				// this.searchKeyFun(this.getListParams.key)
			}
			
		},
		// 下一页
		nextPage() {
			this.getListParams.page += 1;
		},
		//  搜索
		searchKeyFun(keys) {
			if ( this.getListParams.key == keys && this.getListParams.page == 1) {
				this.getList()
			} else {
				this.getListParams.page = 1;
				this.getListParams.key = keys;
			}
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.listStatus.isNull = false;
			this.listStatus.isData = true;
			if ( this.getListParams.page == 1 ) {
				this.$refs.list&&this.$refs.list.clear();
			}
			this.$store
				.dispatch("app/getChartletList", this.getListParams)
				.then((res) => {
					this.loading = false;
					const { data } = res;
					if (data.total == 0) {
						this.$refs.list.clear();
						this.listStatus.isNull = true;
					} else if (data.current_page >= data.last_page) {
						this.listStatus.isData = false;
					}
				});
		},
	},
	mounted() {},
	computed: {
		...mapGetters(["chartletObj"]),
	},

	watch: {
		getListParams: {
			handler: function () {
				this.getList()
			},
			deep: true,
		},
	},
};
</script>
<style scoped>
::v-deep .is-animated {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.pageBox {
	width: 100%;
	text-align: center;
}
.minBox {
	position: relative;
}
.searchBar {
	
	position: absolute;
	width: 8rem;
	top: 145px;
	left: calc(50% - 4rem);
	z-index: 8;
}
.contBox {
	position: relative;
	z-index: 8;
}
.classify {
	height: 1.4rem;
	padding: 0 1.2rem;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0px 0.04rem 0.2rem 0px rgba(194, 194, 194, 0.2);
	user-select: none;
}
.menuBox {
	width: calc(100% + 0.24rem);
	height: 100%;
	margin: 0 -0.12rem;
}
.menu {
	position: relative;
	width: 10%;
	height: 0.76rem;
	padding: 0 0.12rem;
	border-radius: 0.1rem;
	overflow: hidden;
	cursor: pointer;
}
.menuImg{
	width: 100%;
	border-radius: 0.1rem;
	height: 0.76rem;
	overflow: hidden;
}
.menuImg img{
	width: 100%;
	border-radius: 0.1rem;
	height: 0.76rem;
	object-fit: cover;
	transition: all 0.6s;
}
.menuImg img:hover {
	transform: scale(1.2);
}
.menuName {
	pointer-events: none;
	width: calc(100% - 0.24rem);;
	height: 100%;
	border-radius: 0.1rem;
	position: absolute;
	top: 0;
	left: 0.12rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.3);
	font-size: 0.18rem;
	font-weight: 500;
	color: #fff;
}
.tabsBox {
	width: 100%;
	padding: 0.5rem 0 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
}
.tabs {
	cursor: pointer;
	width: 2.2rem;
	height: 0.8rem;
	border-radius: 0.08rem;
	margin: 0 0.2rem;
	border: 2px solid rgba(255, 255, 255, 1);  
	background: rgba(255, 255, 255, 1);
	transition: all 0.6s;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.24rem;
	font-weight: 400;
	color: rgba(65, 61, 44, 1);
}
/* .tabs:hover {
	border: 2px solid rgba(244, 132, 51, 1);
	box-shadow: 0px 0px 8px 0px rgba(244, 132, 51, 0.6);
} */
.tabs.tabsActive {
	border: 2px solid rgba(244, 132, 51, 1);
	box-shadow: 0px 0px 8px 0px rgba(244, 132, 51, 0.6);
}
.tabs img {
	width: 0.56rem;
	height: 0.56rem;
	margin-right: 0.16rem;
}

.ListBox {
}
</style>
